import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import '../css/CustomCheckout.css';

// Initialize Stripe with Connect account
const getStripe = (connectedAccountId) => loadStripe(
  'pk_test_51Q6RPQBgFwzlVzFuz8B3h3r2e2atDaeppAxJPSpkowBwdc4fTW6yOmSoDeAB57wGiKGGdNXpvwZGMinLxHEmePFG00J7R9ZA3W',
  { stripeAccount: connectedAccountId }
);

// Inner form component that handles payment submission
const CheckoutForm = ({ onError, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw submitError;
      }

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required'
      });

      if (error) {
        throw error;
      }

      if (paymentIntent.status === 'succeeded') {
        onSuccess?.(paymentIntent);
      } else {
        throw new Error(`Unexpected payment status: ${paymentIntent.status}`);
      }
    } catch (error) {
      console.error('Payment error:', error);
      setErrorMessage(error.message);
      onError?.(error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="custom-checkout-form">
      <PaymentElement />
      
      {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
      )}
      
      <button 
        type="submit" 
        disabled={!stripe || isProcessing} 
        className="payment-button"
      >
        {isProcessing ? 'Processing...' : 'Pay Now'}
      </button>
    </form>
  );
};

// Main container component
const CustomCheckoutContainer = ({ connectedAccountId, onSuccess, onError }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializePayment = async () => {
      try {
        setLoading(true);
        const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
        const result = await createPaymentIntent({
          connectedAccountId,
          products: [
            { id: 'coffee', quantity: 2 }
          ]
        });

        if (result.data.clientSecret) {
          setClientSecret(result.data.clientSecret);
        } else {
          throw new Error('Failed to create payment intent');
        }
      } catch (err) {
        console.error('Payment initialization error:', err);
        setError(err.message || 'Failed to initialize payment');
        onError?.(err);
      } finally {
        setLoading(false);
      }
    };

    if (connectedAccountId) {
      initializePayment();
    }
  }, [connectedAccountId, onError]);

  if (loading) {
    return <div className="custom-checkout-loading">Loading payment form...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!clientSecret) {
    return <div className="error-message">Unable to initialize payment</div>;
  }

  const stripePromise = getStripe(connectedAccountId);

  return (
    <div className="custom-checkout-container">
      <Elements 
        stripe={stripePromise} 
        options={{
          clientSecret,
          appearance: {
            theme: 'stripe',
            variables: {
              colorPrimary: '#0570de',
              colorBackground: '#ffffff',
              colorText: '#30313d',
              colorDanger: '#df1b41',
              fontFamily: 'system-ui, -apple-system, sans-serif',
              spacingUnit: '4px',
              borderRadius: '4px',
            },
          },
        }}
      >
        <CheckoutForm onSuccess={onSuccess} onError={onError} />
      </Elements>
    </div>
  );
};

export default CustomCheckoutContainer;