import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import ReturnPage from './pages/ReturnPage';
import RefreshPage from './pages/RefreshPage';
import StorePage from './pages/StorePage';
import { AuthProvider } from './contexts/AuthContext';
import SuccessPage from './pages/PaymentSuccessPage';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/return/:accountId" element={<ReturnPage />} />
          <Route path="/refresh/:accountId" element={<RefreshPage />} />
          <Route path="/store/:storeUrl" element={<StorePage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;