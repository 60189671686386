// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator  } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDCY4qWLjgvf1L6gl2eTZtN1_Yrsm-S24g",
  authDomain: "whizpay.store",
  projectId: "whizpay-4207f",
  storageBucket: "whizpay-4207f.appspot.com",
  messagingSenderId: "172392670867",
  appId: "1:172392670867:web:f82b5ba85ddee1d5490f66",
  measurementId: "G-B84TK2F1HE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const auth = getAuth(app);
const db = getFirestore(app);

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { app, functions, auth, db };