import React from "react";
import { useParams } from 'react-router-dom';

export default function ReturnPage() {
  const {accountId} = useParams();

  return (
    <div className="container">
      <div className="banner">
        <h2>WhizPay</h2>
      </div>
      <div className="content">
        <h2>Details submitted ! Connected Account ID: {accountId}</h2>
        <p>That's everything we need for now</p>
      </div>
    </div>
  );
}