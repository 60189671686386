import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import '../css/EmbeddedCheckoutComponent.css';

// Initialize Stripe with Connect account
const getStripe = (connectedAccountId) => loadStripe(
  'pk_test_51Q6RPQBgFwzlVzFuz8B3h3r2e2atDaeppAxJPSpkowBwdc4fTW6yOmSoDeAB57wGiKGGdNXpvwZGMinLxHEmePFG00J7R9ZA3W',
  { stripeAccount: connectedAccountId }
);

const EmbeddedCheckoutContainer = ({ clientSecret, connectedAccountId, onError }) => {
  // Load Stripe instance with connected account
  const stripePromise = getStripe(connectedAccountId);

  return (
    <div className="embedded-checkout-container">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{ clientSecret }}
      >
        <EmbeddedCheckout
          onError={(err) => {
            console.error('Embedded Checkout Error:', err);
            onError?.(err);
          }}
        />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default EmbeddedCheckoutContainer;