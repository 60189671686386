import React, { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { Check } from 'lucide-react';
import '../css/PaymentSuccessPage.css';

const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);

  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        setLoading(true);
        setError(null);

        if (!sessionId) {
          throw new Error('No session ID provided');
        }

        const getPaymentDetails = httpsCallable(functions, 'getPaymentDetails');
        const result = await getPaymentDetails({ sessionId });

        setPaymentDetails(result.data);
      } catch (err) {
        console.error('Error fetching payment details:', err);
        setError(err.message || 'Failed to load payment details');
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [sessionId]);

  if (loading) {
    return (
      <div className="success-page">
        <div className="loading-card">
          <div className="loading-text">Loading payment details...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="success-page">
        <div className="error-card">
          <div className="error-text">{error}</div>
          <Link to="/" className="error-link">
            Return to Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="success-page">
      <div className="success-card">
        <div className="success-icon-wrapper">
          <div className="success-icon">
            <Check />
          </div>
        </div>
        
        <div className="success-header">
          <h1 className="success-title">Payment Successful!</h1>
          <p className="success-subtitle">
            Thank you for your purchase
          </p>
        </div>

        <div className="payment-details">
          <div className="detail-row">
            <span className="detail-label">Amount Paid:</span>
            <span className="detail-value">
              {paymentDetails.currency} {paymentDetails.amount}
            </span>
          </div>
          <div className="detail-row">
            <span className="detail-label">Date:</span>
            <span className="detail-value">
              {paymentDetails.timestamp}
            </span>
          </div>
        </div>

        <div className="home-link-wrapper">
          <Link to="/" className="home-link">
            Return to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;