import React, { useState, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions, auth, db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { signOut } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import AuthModal from '../components/AuthModal';
import '../css/LandingPage.css';

const LandingPage = () => {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [accountLinkCreatePending, setAccountLinkCreatePending] = useState(false);
  const [error, setError] = useState(null);
  const [connectedAccountId, setConnectedAccountId] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      fetchConnectedAccountId();
    }
  }, [currentUser]);

  const fetchConnectedAccountId = async () => {
    if (currentUser) {
      const userDocRef = doc(db, 'userConfig', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists() && userDoc.data().connectedAccountId) {
        setConnectedAccountId(userDoc.data().connectedAccountId);
      }
    }
  };

  const handleCreateAccount = async () => {
    setAccountCreatePending(true);
    setError(null);
    try {
      const createStripeAccount = httpsCallable(functions, 'createStripeAccount');
      const result = await createStripeAccount();
      
      if (result.data.account) {
        setConnectedAccountId(result.data.account);
        
        const userDocRef = doc(db, 'userConfig', currentUser.uid);
        await updateDoc(userDocRef, {
          connectedAccountId: result.data.account
        });
        
        await handleAddInformation(result.data.account);
      } else {
        throw new Error('No account ID received');
      }
    } catch (error) {
      console.error("Error creating Stripe account:", error);
      setError(error.message || 'An error occurred while creating the Stripe account');
    } finally {
      setAccountCreatePending(false);
    }
  };

  const handleAddInformation = async (accountId) => {
    setAccountLinkCreatePending(true);
    setError(null);
    try {
      const createAccountLink = httpsCallable(functions, 'createAccountLink');
      const result = await createAccountLink({ account: accountId || connectedAccountId });
      if (result.data.url) {
        window.location.href = result.data.url;
      } else {
        throw new Error('No account link URL received');
      }
    } catch (error) {
      console.error("Error creating account link:", error);
      setError(error.message || 'An error occurred while creating the account link');
    } finally {
      setAccountLinkCreatePending(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setConnectedAccountId(null);
    } catch (error) {
      console.error("Error signing out:", error);
      setError('An error occurred while signing out');
    }
  };

  return (
    <div className="landing-page">
      <header className="hero">
        <div className="hero-content">
          <h1 className="hero-title">WHIZ PAY</h1>
          <p className="hero-subtitle">Simplify Payments, Securely</p>
        </div>
      </header>
      <div className="content">
        {currentUser ? (
          <div className="user-actions">
            <p>Welcome, {currentUser.name || currentUser.email}!</p>
            <button onClick={handleSignOut} className="sign-out-button">Sign Out</button>
            {!accountCreatePending && !connectedAccountId && (
              <button onClick={handleCreateAccount} className="create-account-button">
                Create a Stripe account
              </button>
            )}
            {connectedAccountId && !accountLinkCreatePending && (
              <button onClick={() => handleAddInformation()} className="add-info-button">
                Add information to Stripe account
              </button>
            )}
          </div>
        ) : (
          <button onClick={() => setShowAuthModal(true)} className="sign-in-button">
            Sign In / Sign Up
          </button>
        )}
        {error && <p className="error">{error}</p>}
        {(connectedAccountId || accountCreatePending || accountLinkCreatePending) && (
          <div className="dev-callout">
            {connectedAccountId && <p>Your connected account ID is: <code className="bold">{connectedAccountId}</code></p>}
            {accountCreatePending && <p>Creating a connected account...</p>}
            {accountLinkCreatePending && <p>Creating a new Account Link...</p>}
          </div>
        )}
      </div>
      {showAuthModal && <AuthModal onClose={() => setShowAuthModal(false)} />}
    </div>
  );
};

export default LandingPage;