import React, { useState } from 'react';
import { auth, db, functions } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import '../css/AuthModal.css';

const AuthModal = ({ onClose }) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [createStripeAccount, setCreateStripeAccount] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (isSignUp) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        let userData = {
          email,
          name,
          creationDate: serverTimestamp()
        };

        if (createStripeAccount) {
          const createStripeAccountFn = httpsCallable(functions, 'createStripeAccount');
          const result = await createStripeAccountFn();
          if (result.data.account) {
            userData.connectedAccountId = result.data.account;
          }
        }

        await setDoc(doc(db, 'userConfig', user.uid), userData);

        if (createStripeAccount && userData.connectedAccountId) {
          const createAccountLink = httpsCallable(functions, 'createAccountLink');
          const linkResult = await createAccountLink({ account: userData.connectedAccountId });
          if (linkResult.data.url) {
            window.location.href = linkResult.data.url;
            return; // Exit early as we're redirecting
          }
        }
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      onClose();
    } catch (error) {
      console.error("Error during authentication:", error);
      setError(error.message);
    }
  };

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>{isSignUp ? 'Create Account' : 'Sign In'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {isSignUp && (
            <>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group checkbox">
                <input
                  type="checkbox"
                  id="createStripeAccount"
                  checked={createStripeAccount}
                  onChange={(e) => setCreateStripeAccount(e.target.checked)}
                />
                <label htmlFor="createStripeAccount">Create a Stripe account for payments</label>
              </div>
            </>
          )}
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="submit-button">
            {isSignUp ? 'Sign Up' : 'Sign In'}
          </button>
        </form>
        <p className="switch-mode">
          {isSignUp ? 'Already have an account?' : 'Need an account?'}
          <button onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp ? 'Sign In' : 'Sign Up'}
          </button>
        </p>
      </div>
    </div>
  );
};

export default AuthModal;