import React, { useState, useEffect } from 'react';
import EmbeddedCheckoutContainer from '../components/EmbeddedCheckoutContainer';
import CustomCheckoutContainer from '../components/CustomCheckoutContainer';
import { useParams, useNavigate } from 'react-router-dom';
import { db, functions } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

const StorePage = () => {
  const { storeUrl } = useParams();
  const navigate = useNavigate();
  const [storeData, setStoreData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const q = query(collection(db, 'userConfig'), where('storeUrl', '==', storeUrl));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          throw new Error('Store not found');
        } else {
          const userData = querySnapshot.docs[0].data();
          setStoreData({
            storeName: userData.storeName || 'Unnamed Store',
            ownerName: userData.name || 'Unknown Owner',
            connectedAccountId: userData.connectedAccountId || 'Not connected',
            checkoutType: userData.checkoutType || 'stripeHosted'
          });
        }
      } catch (err) {
        console.error('Error fetching store data:', err);
        window.location.replace('/404.html');
      } finally {
        setLoading(false);
      }
    };

    fetchStoreData();
  }, [storeUrl]);

  // Try to restore checkout session from URL params
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionSecret = params.get('session');
    if (sessionSecret) {
      setClientSecret(sessionSecret);
    }
  }, []);

  const startCheckout = async () => {
    if (!storeData?.connectedAccountId) return;

    try {
      setCheckoutLoading(true);
      setError(null);

      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSessionEmbedded');
      const result = await createCheckoutSession({
        connectedAccountId: storeData.connectedAccountId,
        products: [
          { id: 'coffee', quantity: 2 }
        ]
      });

      if (result.data.clientSecret) {
        setClientSecret(result.data.clientSecret);
        // Add the session to URL for persistence
        const params = new URLSearchParams(window.location.search);
        params.set('session', result.data.clientSecret);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
      } else {
        throw new Error('Failed to create checkout session');
      }
    } catch (err) {
      console.error('Error starting checkout:', err);
      setError(err.message || 'Failed to start checkout');
    } finally {
      setCheckoutLoading(false);
    }
  };

  const resetCheckout = () => {
    setClientSecret(null);
    const params = new URLSearchParams(window.location.search);
    params.delete('session');
    window.history.replaceState({}, '', window.location.pathname);
  };

  const handlePaymentSuccess = (paymentIntent) => {
    // Navigate to success page or show success message
    navigate(`/success?payment_intent=${paymentIntent.id}`);
  };

  const handlePaymentError = (error) => {
    setError(error.message);
  };

  if (loading) {
    return <div>Loading store details...</div>;
  }

  return (
    <div className="store-page">
      <h1>{storeData.storeName}</h1>
      <p>Store Owner: {storeData.ownerName}</p>
      
      {error && (
        <div className="error-message">
          {error}
          <button onClick={() => setError(null)} className="ml-2">
            Dismiss
          </button>
        </div>
      )}

      {storeData.checkoutType === 'embedded' && !clientSecret && (
        <button 
          onClick={startCheckout}
          disabled={checkoutLoading}
          className="checkout-button"
        >
          {checkoutLoading ? 'Starting checkout...' : 'Start Checkout (2 Coffees)'}
        </button>
      )}
      
      {storeData.checkoutType === 'embedded' && clientSecret && (
        <div className="embedded-checkout-wrapper">
          <EmbeddedCheckoutContainer 
            clientSecret={clientSecret}
            connectedAccountId={storeData.connectedAccountId}
            onError={(err) => {
              setError(err.message);
              resetCheckout();
            }}
          />
          <button 
            onClick={resetCheckout}
            className="cancel-button mt-4"
          >
            Cancel Checkout
          </button>
        </div>
      )}

      {storeData.checkoutType === 'custom' && storeData.connectedAccountId && (
        <div className="custom-checkout-wrapper">
          <CustomCheckoutContainer 
            connectedAccountId={storeData.connectedAccountId}
            onSuccess={handlePaymentSuccess}
            onError={handlePaymentError}
          />
        </div>
      )}
    </div>
  );
};

export default StorePage;